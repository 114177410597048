// .wrapper-section-handbook {
//   background-color: rgb(230, 227, 227);

//   .section-handbook {
//     background: rgb(230, 227, 227);
//     margin-bottom: 0 !important;

//     & > div:hover {
//       cursor: pointer;
//     }
//   }

//   .slick-slide {
//     div {
//       div {
//         display: flex !important;
//       }
//     }
//   }

//   img {
//     width: 50% !important;
//     margin-right: 10px;
//   }

//   p {
//     font-size: 18px;
//   }
// }

.wrapper-section-handbook {
  background-color: rgb(230, 227, 227);

  .section-handbook {
    background: rgb(230, 227, 227);

    .handbook-child {
      cursor: pointer;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding: 20px 20px;
      border: 1px solid #06b0d5;
      background-color: aliceblue;
      border-radius: 12px;

      & > .img-handbook {
        border-radius: 8px;
        width: 50% !important;
      }

      .name-handbook {
        padding: 0 10px;
        font-weight: 600;
        color: #06b0d5;
        text-align: center;

        &:hover {
          color: #aae1ee;
        }
      }
    }
  }
}
