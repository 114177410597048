.register-background {
  height: 100vh;
  background: linear-gradient(135deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);

  .register-container {
    height: fit-content;
    padding-bottom: 16px;
    width: 480px;
    margin: auto;
    border-radius: 10px;
    background-color: white;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .register {
      padding-top: 10px;

      & > .link {
        color: #00d0ff;
      }
      & > .link:hover {
        color: #71d7ed;
      }
    }

    .register-content.row {
      justify-content: center;
      margin-top: 15px;
      .text-register {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        padding-bottom: 12px;
      }

      div > span {
        color: #f00;
        display: none;
      }

      label > span {
        color: #f00;
      }

      input:invalid[focused='true'] {
        border: 1px solid #f00;
      }

      input:invalid[focused='true'] ~ span {
        display: block;
      }

      .form-group {
        height: 72px;
      }

      .space {
        padding-right: 8px;
        transform: translateX(-15px);
      }

      .space-right {
        transform: translateX(15px);
      }

      .inline {
        display: flex;
      }

      .register-input {
        .custom-input-password {
          position: relative;

          i {
            position: absolute;
            top: 10px;
            right: 8px;
            cursor: pointer;
          }
        }
      }

      .btn-register {
        font-size: 16px;
        color: white;

        width: 100%;
        height: 30px;
        margin: 12px 0;
        border-radius: 24px;
        outline: none;
        border: none;
        background: linear-gradient(135deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
      }

      .forgot-password {
        font-size: 12px;
      }

      .social-register {
        display: flex;
        justify-content: center;
        padding-top: 16px;

        i {
          font-size: 32px;
          padding: 0 8px;
          cursor: pointer;
        }

        .facebook::before {
          color: #3b5998;
        }

        .twitter::before {
          color: #55acee;
        }

        .google::before {
          color: #dc4e41;
        }
      }

      .link > a {
        color: #00d0ff;
        font-weight: bold;
      }

      .link > a:hover {
        text-decoration: none;
        color: #71d7ed;
      }
    }
  }
}
