.manage-schedule-container {
  .m-s-title {
    text-transform: uppercase;
    margin-top: 15px;
    font-weight: 600;
    font-size: 17px;
    text-align: center;
    margin-bottom: 15px;
  }

  .pick-hour-container {
    height: 150px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;

    .btn-schedule {
      // border: 1px solid #ccc;
      border-radius: 5px;
      padding: 5px 15px;
      border: none;
      font-weight: bold;

      &.active {
        background-color: rgb(52, 248, 46);
      }
    }
  }

  .btn-save-schedule {
    margin-top: 20px;
  }
}
