.preview-img-container {
    .label-upload {
        padding: 5px;
        border-radius: 3px;
        background-color: #ddd;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }

        i {
            margin-left: 5px
        }
    }

    .preview-image {
        height: 50px;
        width: 100%;
        border: 1px solid #ddd;
        background: center center no-repeat;
        background-size: contain;
        cursor: pointer;
    }
}