.wrapper-section-medical-facility {
  background-color: rgb(230, 227, 227);

  .section-medical-specility {
    background: rgb(230, 227, 227);

    .clinic-child {
      cursor: pointer;
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 20px 0;
      min-height: 278px;
      border: 1px solid #06b0d5;
      background-color: aliceblue;
      border-radius: 12px;

      & > .section-medical-facility {
        border-radius: 8px;
      }

      .clinic-name {
        padding: 20px 0;
        font-weight: 600;
        color: #06b0d5;
        text-align: center;

        &:hover {
          color: #aae1ee;
        }
      }
    }
  }
}
