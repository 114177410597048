.login-background {
  background: linear-gradient(135deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-container {
    width: 400px;
    height: 600px;
    background-color: white;
    border-radius: 10px;

    .login-content {
      margin: 10px;

      .home {
        padding-top: 10px;

        & > .link {
          color: #00d0ff;
        }
        & > .link:hover {
          color: #71d7ed;
        }
      }

      .row-user-name {
        margin: 25px 0;
        label {
          padding-left: 10px;
          opacity: 0.5;
          font-size: 16px;
          font-weight: 500;
        }
        input {
          font-size: 20px;
          padding: 10px 10px;
          border: none;
          border-bottom: 2px solid #ccc;
        }
      }
      .row-password {
        margin-bottom: 20px;
        label {
          padding-left: 10px;
          opacity: 0.5;
          font-size: 16px;
          font-weight: 500;
        }

        .custom-input-password {
          position: relative;
          input {
            font-size: 20px;
            padding: 10px 10px;
            border: none;
            border-bottom: 2px solid #ccc;
          }
          i {
            position: absolute;
            right: 5px;
            top: 15px;
            font-size: 20px;
            z-index: 2;
            cursor: pointer;
          }
        }
      }

      .btn-login {
        width: 100%;
        margin: 14px 0;
        text-align: center;
        button {
          width: 100%;
          border: none;
          background: linear-gradient(135deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
          font-weight: 600;
          font-size: 18px;
          color: white;
          border-radius: 15px;
        }
      }

      .btn-login:hover {
        opacity: 0.7;
      }

      .login-forgot {
        width: 100%;
        text-align: center;
        span {
          margin-top: 10px;
        }

        span:hover {
          cursor: pointer;
        }
      }

      .login-icon {
        width: 100%;
        text-align: center;
      }

      .login-more {
        margin-top: 36px;
      }

      .login-icon {
        margin-top: 4px;

        i {
          font-size: 40px;
          margin: 10px 10px;
        }

        i:hover {
          cursor: pointer;
        }

        .google {
          color: rgb(212, 36, 36);
        }

        .twitter {
          color: rgb(65, 199, 240);
          border-radius: 50%;
        }

        .facebook {
          color: rgb(57, 57, 131);
          border-radius: 50%;
        }
      }

      .link > a {
        color: #00d0ff;
        font-weight: bold;
      }

      .link > a:hover {
        text-decoration: none;
        color: #71d7ed;
      }
    }
  }
}
