#TableManageUser {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#TableManageUser td,
#TableManageUser th {
    border: 1px solid #ddd;
    padding: 8px;
}

#TableManageUser tr:nth-child(even) {
    background-color: #f2f2f2;
}

#TableManageUser tr:hover {
    background-color: #ddd;
}

#TableManageUser th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
}