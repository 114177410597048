.manage-doctor-container {
    padding: 0 50px;

    .manage-doctor-title {
        text-align: center;
        padding: 40px;
        margin-bottom: 30px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
        color: #0071ba;
    }

    .save-content-doctor {
        margin-top: 20px;
        margin-bottom: 30px;
        margin-left: 15px;
        border-radius: 5px;
        outline: none;
        border: none;
        background-color: #0071ba;
        padding: 5px;
        color: white;

        &:hover {
            opacity: 0.8;
        }
    }

    .create-content-doctor {
        margin-top: 20px;
        margin-bottom: 30px;
        margin-left: 15px;
        border-radius: 5px;
        outline: none;
        border: none;
        background-color: #ea830d;
        padding: 5px;
        color: white;

        &:hover {
            opacity: 0.8;
        }
    }

    .more-infor {
        display: flex;
        gap: 20px;
        padding-bottom: 30px;

        .content-left {
            width: 35%;
        }

        .content-right {
            width: 65%;
        }
    }
}