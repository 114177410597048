.doctor-extra-info-container {
  padding-left: 15px;

  .content-up {
    margin-bottom: 10px;

    .text-address {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      color: #333;
    }

    .name-clinic {
      padding: 5px 0;
      font-weight: 600;
      color: #333;
    }

    .detail-address {
      color: #333;
    }
  }

  .content-down {
    .short-info {
      padding: 10px 0;

      .title-price {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
      }

      .detail {
        cursor: pointer;
        color: rgb(38, 160, 231);
      }

      .currency {
        margin: 0 5px;
      }
    }

    .title-price {
      padding: 10px 0;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      border-top: 1px solid #ddd;
    }

    .detail-info {
      border: 1px solid #ddd;
      padding: 5px;
      background-color: #f8f8f8;

      .price {
        padding-bottom: 5px;

        .right {
          float: right;
        }
      }

      .note {
      }
    }

    .payment {
      border: 1px solid #ddd;
      padding: 5px;
      background-color: #eee;
    }

    .hide-price {
      margin-top: 10px;

      span {
        cursor: pointer;
        color: rgb(38, 160, 231);
      }
    }
  }
}
