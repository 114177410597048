#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}



.modal-user-container {
  .modal-header {
    button {
      border: none;
      background-color: transparent;
      color: white;
      padding: 0;

      span {
        font-size: 30px;
      }
    }
  }

  .modal-user-body {
    .modal-row {
      display: flex;
      gap: 20px;

      .input-container {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        width: 100%;

        input {
          border-radius: 3px;
          height: 35px;
          border: none;
          border-bottom: 2px solid #ccc;
          outline: none;
          padding: 10px;
          font-size: 18px;
        }
      }
    }
  }

  .modal-footer {
    button {
      padding: 0 10px;
    }
  }
}