div._loading_overlay_wrapper {
  position: unset;

  ._loading_overlay_overlay {
    z-index: 100;
  }
}

.manage-patient-container {
  .m-p-title {
    font-size: 17px;
    font-weight: 600;

    margin: 10px 0;
    text-align: center;
    text-transform: uppercase;
  }

  .manage-patient-body {
    padding: 0 20px;

    .table-manage-patient {
      table,
      th,
      td {
        border: 1px solid black;
        border-collapse: collapse;
      }

      th,
      td {
        padding: 5px;
        text-align: left;
      }
    }

    button {
      border: none;
      outline: none;
      cursor: pointer;
    }

    .mp-btn-confirm {
      margin-right: 10px;
      height: 30px;
      border: radius 3px;
      background: orange;
    }

    .mp-btn-remedy {
      height: 30px;
      border: radius 3px;
      background: #39dca0;
    }
  }
}
