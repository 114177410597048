.verify-email-container {
  transform: translateY(100px);
  .info-booking {
    font-size: 17px;
    font-weight: 600;
    margin-top: 30px;
    text-align: center;
    text-transform: uppercase;
    color: red;
  }
}
