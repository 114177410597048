.doctor-schedule-container {
  border-right: 1px solid #ddd;
  height: 100%;

  .all-schedule {
    select {
      border: none;
      outline: none;
      border-bottom: 2px solid #ddd;
      cursor: pointer;
      padding: 5px 0;
      color: #337ab7;
      font-weight: 600;
    }
  }

  .all-available-time {
    .text-calendar {
      margin: 10px 0;
      color: #333;

      span {
        text-transform: uppercase;
        margin-left: 6px;
      }
    }

    .time-content {
      .time-content-btns {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;

        button {
          padding: 5px 10px;
          border: none;
          outline: none;
          background-color: #fff04b;
          min-width: 120px;
          border-radius: 3px;
          font-weight: 600;
          color: #333;

          &:hover {
            background-color: #45c3d2;
          }
        }

        .btn-vie {
          min-width: 120px;
        }

        .btn-en {
          min-width: 170px;
        }

        .no-schedule {
          font-size: 14px;
          font-style: italic;
        }
      }

      .book-free {
        margin-top: 8px;
      }
    }
  }
}
