@import "../../styles/common";

$bezier: cubic-bezier(0.23, 1, 0.32, 1);
$slideTime: 400ms;

$daySize: 39px;
$padding: $daySize / 16;
$dayMargin: 2px;
$daysWidth: $daySize * 7 + $dayMargin * 14 + $padding * 2 + 2;
$calendarWidth: $daysWidth;

$monthNavHeight: 28px !default;
$weekdaysHeight: 28px !default;
$timeHeight: 40px;

$disabledBorderColor: transparent;

$selectedDayForeground: #fff;
$selectedDayBackground: $colormain;

input {
  &.custom-date-input {
    word-spacing:-3px
  }
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@-ms-keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@-o-keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: $calendarWidth;
  box-sizing: border-box;
  touch-action: manipulation;

  &.open, &.inline {
    opacity: 1;
    max-height: 640px;
    visibility: visible;
  }

  &.open {
    display: inline-block;
    z-index: 99999;
  }

  &.animate.open {
    animation: fpFadeInDown 300ms $bezier;
  }

  &.inline {
    display: block;
    position: relative;
    top: 2px;
  }

  &.static {
    position: absolute;
    top: calc(100% + 2px);

    &.open {
      z-index: 999;
      display: block;
    }
  }

  &.multiMonth {
    .flatpickr-days .dayContainer:nth-child(n+1) {
      & .flatpickr-day.inRange:nth-child(7n+7) {
        box-shadow: none !important;
      }
    }

    .flatpickr-days .dayContainer:nth-child(n+2) {
      & .flatpickr-day.inRange:nth-child(7n+1) {
        box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
      }
    }
  }

  .hasWeeks, .hasTime {
    .dayContainer {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.showTimeInput.hasTime {
    .flatpickr-time {
      height: $timeHeight;
    }
  }

  &.noCalendar.hasTime {
    .flatpickr-time {
      height: auto;
    }
  }

  &:before, &:after {
    position: absolute;
    display: block;
    pointer-events: none;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    left: 22px;
  }

  &.rightMost {
    &:before, &:after {
      left: auto;
      right: 22px;
    }
  }

  &:before {
    border-width: 5px;
    margin: 0 -5px;
  }

  &:after {
    border-width: 4px;
    margin: 0 -4px;
  }

  &.arrowTop {
    &:before, &:after {
      bottom: 100%;
    }
  }

  &.arrowBottom {
    &:before, &:after {
      top: 100%;
    }
  }

  &:focus {
    outline: 0;
  }

  .flatpickr-months {
    display: flex;

    .flatpickr-month {
      height: $monthNavHeight;
      line-height: 1;
      text-align: center;
      position: relative;
      user-select: none;
      overflow: hidden;
      flex: 1;
    }

    .flatpickr-prev-month, .flatpickr-next-month {
      text-decoration: none;
      cursor: pointer;
      position: absolute;
      top: 0;
      line-height: 16px;
      height: $monthNavHeight;
      padding: 10px;
      z-index: 3;

      &.disabled {
        display: none;
      }

      i {
        position: relative;
      }

      &.flatpickr-prev-month {
        left: 0;
      }


      &.flatpickr-next-month {
        right: 0;
      }

      svg {
        width: 14px;
        height: 14px;

        path {
          transition: fill 0.1s;
        }
      }
    }
  }

  background: $date-picker-bg;
  box-shadow: 1px 0 0 $date-picker-border, -1px 0 0 $date-picker-border, 0 1px 0 $date-picker-border, 0 -1px 0 $date-picker-border, 0 3px 13px rgba(black, 0.08);

  &.arrowTop {
    &:before {
      border-bottom-color: $date-picker-border;
    }
    &:after {
      border-bottom-color: $date-picker-bg;
    }
  }

  &.arrowBottom {
    &:before {
      border-top-color: $date-picker-border;
    }
    &:after {
      border-top-color: $date-picker-bg;
    }
  }

  &.showTimeInput.hasTime {
    .flatpickr-time {
      border-top: 1px solid $date-picker-border;
    }
  }

  .flatpickr-months {
    .flatpickr-month {
      background: $date-picker-month-bg;
      color: $date-picker-month-fg;
      fill: $date-picker-month-fg;
    }

    .flatpickr-prev-month, .flatpickr-next-month {
      color: $date-picker-month-fg;
      fill: $date-picker-month-fg;

      &:hover {
        color: $date-picker-today-bg;
        svg {
          fill: $date-picker-arrow-hover;
        }
      }
    }
  }
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.numInputWrapper {
  position: relative;
  height: auto;

  input, span {
    display: inline-block;
  }

  input {
    width: 100%;
    &::-ms-clear {
      display: none;
    }

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }

  span {
    position: absolute;
    right: 0;
    width: 14px;
    padding: 0 4px 0 2px;
    height: 50%;
    line-height: 50%;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;

    &:after {
      display: block;
      content: "";
      position: absolute;
    }

    &.arrowUp {
      top: 0;
      border-bottom: 0;

      &:after {
        top: 26%;
      }
    }

    &.arrowDown {
      top: 50%;

      &:after {
        top: 40%;
      }
    }

    svg {
      width: inherit;
      height: auto;
    }
  }

  &:hover {
    span {
      opacity: 1;
    }
  }

    span {
      border: 1px solid rgba($date-picker-day-fg, 0.15);

      &:hover {
        background: rgba(invert($date-picker-bg), 0.1);
      }

      &:active {
        background: rgba(invert($date-picker-bg), 0.2);
      }

      &.arrowUp {
        &:after {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 4px solid rgba($date-picker-day-fg, 0.6);
        }
      }

      &.arrowDown {
        &:after {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid rgba($date-picker-day-fg, 0.6);
        }
      }

      svg {
        path {
          fill: rgba($date-picker-month-fg, 0.5);
        }
      }
    }

    &:hover {
      background: rgba(invert($date-picker-bg), 0.05);
    }
}

.flatpickr-current-month {
  font-size: 135%;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 0.22 * $monthNavHeight 0 0 0;
  line-height: 1;
  height: $monthNavHeight;
  display: inline-block;
  text-align: center;
  transform: translate3d(0px, 0px, 0px);

  span.cur-month {
    font-family: inherit;
    font-weight: 700;
    color: inherit;
    display: inline-block;
    margin-left: 0.5ch;
    padding: 0;
  }

  .numInputWrapper {
    width: 6ch;
    display: inline-block;
  }

  input.cur-year {
    background: transparent;
    box-sizing: border-box;
    color: inherit;
    cursor: text;
    padding: 0 0 0 0.5ch;
    margin: 0;
    display: inline-block;
    font-size: inherit;
    font-family: inherit;
    font-weight: 300;
    line-height: inherit;
    height: auto;
    border: 0;
    border-radius: 0;
    vertical-align: initial;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;

    &:focus {
      outline: 0;
    }

    &[disabled], &[disabled]:hover {
      font-size: 100%;
      pointer-events: none;
    }
  }

    span.cur-month {
      &:hover {
        background: rgba(invert($date-picker-bg), 0.05);
      }
    }

    .numInputWrapper {
      span.arrowUp:after {
        border-bottom-color: $date-picker-month-fg;
      }

      span.arrowDown:after {
        border-top-color: $date-picker-month-fg;
      }
    }

    input.cur-year {
      &[disabled], &[disabled]:hover {
        color: rgba($date-picker-month-fg, 0.5);
        background: transparent;
      }
    }
}

.flatpickr-monthDropdown-months {
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: $monthNavHeight - 6px;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: auto;

  &:focus, &:active {
    outline: none;
  }

  .flatpickr-monthDropdown-month {
    outline: none;
    padding: 0;
  }

    background: $date-picker-month-bg;

    &:hover {
      background: rgba(invert($date-picker-bg), 0.05);
    }

    .flatpickr-monthDropdown-month {
      background-color: $date-picker-month-bg;
    }
}

.flatpickr-weekdays {
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: $weekdaysHeight;

  .flatpickr-weekdaycontainer {
    display: flex;
    flex: 1;
  }

  background: $date-picker-weekdays-bg;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-weight: bolder;

    background: $date-picker-month-bg;
    color: $date-picker-weekdays-fg;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: $daysWidth;

  &:focus {
    outline: 0;
  }
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: $daysWidth;
  min-width: $daysWidth;
  max-width: $daysWidth;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-around;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;

    & + .dayContainer {
      box-shadow: -1px 0 0 $date-picker-border;
    }
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  cursor: pointer;

  font-weight: 400;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: $daySize;
  height: $daySize;
  line-height: $daySize;
  margin: 0;

  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;

  &, &.prevMonthDay, &.nextMonthDay {
    &.inRange, &.today.inRange, &:hover, &:focus {
      cursor: pointer;
      outline: 0;
    }
  }

  &.selected, &.startRange, &.endRange {
    &.startRange {
      border-radius: 50px 0 0 50px;
    }

    &.endRange {
      border-radius: 0 50px 50px 0;
    }

    &.startRange.endRange {
      border-radius: 50px;
    }
  }

  &.inRange {
    border-radius: 0;
  }

  &.flatpickr-disabled, &.flatpickr-disabled:hover,
  &.prevMonthDay, &.nextMonthDay,
  &.notAllowed, &.notAllowed.prevMonthDay, &.notAllowed.nextMonthDay {
    cursor: default;
  }

  &.flatpickr-disabled, &.flatpickr-disabled:hover {
    cursor: not-allowed;
  }

  &.week.selected {
    border-radius: 0;
  }

  &.hidden {
    visibility: hidden;
  }

    background: none;
    border: 1px solid transparent;
    color: $date-picker-day-fg;

    &, &.prevMonthDay, &.nextMonthDay {
      &.inRange, &.today.inRange, &:hover, &:focus {
        background: $date-picker-day-hover-bg;
        border-color: $date-picker-day-hover-bg;
      }
    }

    &.today {
      border-color: $date-picker-today-border;

      &:hover, &:focus {
        border-color: $date-picker-today-border;
        background: $date-picker-today-bg;
        color: $date-picker-today-fg;
      }
    }

    &.selected, &.startRange, &.endRange {
      &, &.inRange, &:focus, &:hover, &.prevMonthDay, &.nextMonthDay {
        background: $selectedDayBackground;
        box-shadow: none;
        color: $selectedDayForeground;
        border-color: $selectedDayBackground;
      }

      &.startRange + .endRange:not(:nth-child(7n+1)) {
        box-shadow: -5 * $dayMargin 0 0 $selectedDayBackground;
      }
    }

    &.inRange {
      border-radius: 0;
      box-shadow: -2.5 * $dayMargin 0 0 $date-picker-day-hover-bg, 2.5 * $dayMargin 0 0 $date-picker-day-hover-bg;
    }

    &.flatpickr-disabled, &.flatpickr-disabled:hover,
    &.prevMonthDay, &.nextMonthDay,
    &.notAllowed, &.notAllowed.prevMonthDay, &.notAllowed.nextMonthDay {
      color: rgba($date-picker-day-fg, 0.3);
      background: transparent;
      border-color: $disabledBorderColor;
    }

    &.flatpickr-disabled, &.flatpickr-disabled:hover {
      color: rgba($date-picker-day-fg, 0.1);
    }
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;

  .flatpickr-weeks {
    padding: 0 12px;
  }

  .flatpickr-weekday {
    float: none;
    width: 100%;
    line-height: $weekdaysHeight;
  }

  span.flatpickr-day {
    &, &:hover {
      display: block;
      width: 100%;
      max-width: none;
      cursor: default;
    }
  }

    .flatpickr-weeks {
      box-shadow: 1px 0 0 $date-picker-border;
    }

    span.flatpickr-day {
      &, &:hover {
        color: rgba($date-picker-day-fg, 0.3);
        background: transparent;
        border: none;
      }
    }
}

.flatpickr-innerContainer {
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  height: 0;
  line-height: $timeHeight;
  max-height: $timeHeight;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .numInputWrapper {
    flex: 1;
    width: 40%;
    height: $timeHeight;
    float: left;
  }

  &.hasSeconds .numInputWrapper {
    width: 26%;
  }

  &.time24hr .numInputWrapper {
    width: 49%;
  }

  input {
    background: transparent;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    text-align: center;
    margin: 0;
    padding: 0;
    height: inherit;
    line-height: inherit;
    font-size: 14px;
    position: relative;
    box-sizing: border-box;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;

    &.flatpickr-hour {
      font-weight: bold;
    }

    &.flatpickr-minute, &.flatpickr-second {
      font-weight: 400;
    }

    &:focus {
      outline: 0;
      border: 0;
    }
  }

  .flatpickr-time-separator, .flatpickr-am-pm {
    height: inherit;
    float: left;
    line-height: inherit;
    font-weight: bold;
    width: 2%;
    user-select: none;
    align-self: center;
  }

  .flatpickr-am-pm {
    outline: 0;
    width: 18%;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
  }

    .numInputWrapper {
      span.arrowUp:after {
        border-bottom-color: $date-picker-day-fg;
      }

      span.arrowDown:after {
        border-top-color: $date-picker-day-fg;
      }
    }

    input {
      color: $date-picker-day-fg;
    }

    .flatpickr-time-separator, .flatpickr-am-pm {
      color: $date-picker-day-fg;
    }

    input, .flatpickr-am-pm {
      &:hover, &:focus {
        background: lighten($date-picker-day-hover-bg, 3);
      }
    }
}

.flatpickr-input[readonly] {
  cursor: pointer;
}
