@import '../../styles/common.scss';

.header-container {
  z-index: 99;
  display: flex;
  justify-content: space-between;
  background-color: $colormain;
  color: $common-text-color;
  height: 40px;
  position: relative;
  align-items: center;

  .languages {
    margin-right: 20px;

    .link {
      margin-right: 10px;
      color: white;
    }

    .welcome {
      margin-right: 10px;
    }

    .language-vi {
      margin-right: 15px;
      cursor: pointer;

      &.active {
        opacity: 1;
        color: rgb(255, 27, 27);
      }
    }

    .language-en {
      margin-right: 15px;
      cursor: pointer;

      &.active {
        opacity: 1;
        color: rgb(255, 27, 27);
      }
    }
  }

  .btn-logout {
    color: $common-text-color;
    line-height: 40px;
    height: 40px;
    padding: 0 10px;

    &:hover {
      background-color: darken($colormain, 5);
    }

    i {
      font-size: $base-size + 1px;
    }
  }
}
